<template>
  <div id="user-list">
    <v-card class="mb-12">
      <v-card-title v-t="'searchAndFilter'" />
      <v-row class="px-2 ma-0">
        <v-col cols="12" sm="4">
          <v-select
            v-model="resourceFilter"
            :placeholder="$t('selectResource')"
            :items="choices.resourceChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
            :item-text="(item) => $t(item.text)"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="ownerFilter"
            :placeholder="$t('selectAssignedTo')"
            :items="choices.recruiterChoices"
            outlined
            dense
            clearable
            multiple
            hide-details
            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-switch
            v-model="hideDoneReminders"
            hide-details
            class="mt-0"
            :label="$t('hideDoneReminders')"
            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
          />
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          :placeholder="$t('search')"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
          @keydown="delaySearchHandler"
        >
        </v-text-field>
      </v-card-text>

      <v-data-table
        show-expand
        :headers="filteredTableColumns"
        :items="reminders"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalReminderListTable"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPage'),
          'items-per-page-options': [10, 50, 250],
        }"
      >
        <template #[`top`]>
          <v-dialog v-model="isDeleteDialogVisible" max-width="650px" persistent>
            <v-card class="pa-sm-10 pa-3">
              <v-card-title v-t="'delete'" class="justify-center text-h5" />
              <v-card-text>
                <v-row>
                  <v-col v-t="'confirmationModalUndoneQuestion'" cols="12" class="d-flex align-center justify-center" />
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined class="me-3" @click="discardDelete" />
                    <v-btn v-t="'delete'" color="error" @click="confirmDelete" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="isUpdateDialogVisible" persistent max-width="650px" @click:outside="discardUpdate">
            <v-card v-if="isUpdateDialogVisible" class="user-edit-info pa-sm-10 pa-3">
              <v-card-title class="justify-center text-h5"> {{ $t('edit') }} </v-card-title>

              <v-card-text class="mt-5">
                <v-form ref="updateReminderForm" class="multi-col-validation" @submit.prevent="confirmUpdate">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="reminderToUpdate.title"
                        outlined
                        dense
                        maxlength="150"
                        :label="$t('title')"
                        :placeholder="$t('title')"
                        :rules="[validators.required]"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-menu
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="reminderToUpdate.remindDate"
                            :label="$t('remindDate')"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="attrs"
                            :rules="[validators.required]"
                            hide-details="auto"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="reminderToUpdate.remindDate"
                          :first-day-of-week="1"
                          :locale="$i18n.locale"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12">
                      <v-autocomplete
                        :value="getFileReminderToUpdate"
                        :label="$t('file')"
                        :placeholder="$t('file')"
                        :items="filesChoices"
                        outlined
                        dense
                        clearable
                        hide-details
                        @input="setFileReminderToUpdate($event)"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="12">
                      <upgrade-alert-wrapper>
                        <template #blocked-features>
                          <v-col cols="12">
                            <v-autocomplete
                              v-model="reminderToUpdate.owner"
                              :label="$t('assignedTo')"
                              :placeholder="$t('assignedTo')"
                              :items="ownerChoices"
                              outlined
                              dense
                              clearable
                              hide-details
                              :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                            ></v-autocomplete>
                          </v-col>
                        </template>
                      </upgrade-alert-wrapper>
                    </v-col>

                    <v-col cols="12" class="d-flex justify-center mt-3">
                      <v-btn outlined color="secondary" class="me-3" @click.prevent="discardUpdate">
                        {{ $t('discard') }}
                      </v-btn>
                      <v-btn color="primary" type="submit" :loading="updateLoading">
                        {{ $t('save') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template #[`header.title`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template #[`header.assignedResourceType`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template #[`header.remindDate`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template #[`header.isAssignedToFile`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template #[`header.owner`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template #[`header.doneDate`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template #[`header.actions`]="{ header }">
          {{ $t(header.text) }}
        </template>

        <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <td v-if="item.isAssignedToFile" class="text-start">
            <v-btn
              icon
              class="v-data-table__expand-icon"
              :class="{ 'v-data-table__expand-icon--active': isExpanded }"
              @click="expand(!isExpanded)"
            >
              <v-icon>{{ icons.mdiChevronDown }}</v-icon>
            </v-btn>
          </td></template
        >

        <template v-slot:expanded-item="{ headers, item }">
          <td v-if="item.isAssignedToFile" :colspan="headers.length" class="px-0">
            <object
              v-if="getFileExtensionFromUrl(item[`${item.assignedResourceType}FileUrl`]) === 'pdf'"
              :data="item[`${item.assignedResourceType}FileUrl`]"
              type="application/pdf"
              class="PDF-Viewer__object w-full"
            />
            <img
              v-else
              :src="item[`${item.assignedResourceType}FileUrl`]"
              :alt="item[`${item.assignedResourceType}FileName`]"
              class="Image-Viewer__object w-full"
            />
          </td>
        </template>

        <template #[`item.title`]="{ item }">
          <div :class="item.isDone ? 'text-decoration-line-through' : ''">{{ item.title }}</div>
        </template>

        <template #[`item.assignedResourceType`]="{ item }">
          <router-link
            :to="
              item.assignedResourceType !== 'candidateOfferDetail'
                ? { name: `apps-${item.assignedResourceType}-view`, params: { id: item[item.assignedResourceType] } }
                : {
                    name: 'apps-ats-candidate-view',
                    params: {
                      jobId: item[`${item.assignedResourceType}JobId`],
                      candidateId: item[`${item.assignedResourceType}CandidateId`],
                    },
                  }
            "
            class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ $t(item.assignedResourceType) }}: {{ item[`${item.assignedResourceType}Name`] }}
          </router-link>
        </template>

        <template #[`item.remindDate`]="{ item }">
          <v-chip small label :color="reminderChipColor(item)">
            <v-icon left>
              {{ icons.mdiBellOutline }}
            </v-icon>
            {{ formatDateToMonthShort(item.remindDate, false) }}
          </v-chip>
        </template>

        <template #[`item.isAssignedToFile`]="{ item }">
          <v-tooltip v-if="item.isAssignedToFile" bottom>
            <template #activator="tooltipActivator">
              <v-icon v-bind="tooltipActivator.attrs" v-on="tooltipActivator.on">{{
                icons.mdiFileAccountOutline
              }}</v-icon>
            </template>
            <span v-t="item[`${item.assignedResourceType}FileName`]" />
          </v-tooltip>
          <div v-else>{{ emptyValueFormatter() }}</div>
        </template>

        <template #[`item.owner`]="{ item }">
          {{ emptyValueFormatter(item.ownerName, false) }}
        </template>

        <template #[`item.doneDate`]="{ item }">
          {{ formatDateToMonthShort(item.doneDate) }}
        </template>

        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <v-tooltip bottom>
              <template #activator="tooltipActivator">
                <v-btn
                  icon
                  small
                  color="error"
                  v-bind="tooltipActivator.attrs"
                  v-on="tooltipActivator.on"
                  @click="deleteItem(item.id)"
                >
                  <v-icon size="18">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'delete'" />
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn icon color="primary" small v-bind="attrs" @click="updateReminder(item)" v-on="on">
                  <v-icon size="18">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="'edit'" />
            </v-tooltip>

            <v-tooltip v-if="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  :color="item.isDone ? 'warning' : 'success'"
                  small
                  v-bind="attrs"
                  @click="updateDone(item)"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ item.isDone ? icons.mdiRefresh : icons.mdiCheck }}
                  </v-icon>
                </v-btn>
              </template>
              <span v-t="item.isDone ? 'unDone' : 'done'" />
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiRefresh,
  mdiCheck,
  mdiPencilOutline,
  mdiFileAccountOutline,
  mdiChevronDown,
} from '@mdi/js'
import { computed, getCurrentInstance, ref, watch } from '@vue/composition-api'

import { avatarText, emptyValueFormatter, formatDateToMonthShort } from '@core/utils/filter'
import { useActions } from 'vuex-composition-helpers'
import _ from 'lodash'
import { required } from '@core/utils/validation'
import useRemindersList from './useRemindersList'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import UpgradeAlertWrapper from '@/components/UpgradeAlertWrapper.vue'
import useFileTable from '@/components/files-table/useFileTable'

export default {
  components: { UpgradeAlertWrapper },
  setup() {
    const {
      updateReminder: updateReminderAction,
      deleteReminder,
      getFilesChoices,
    } = useActions('staff', ['updateReminder', 'deleteReminder', 'getFilesChoices'])
    const { getRecruitersChoices } = useActions('company', ['getRecruitersChoices'])

    const vm = getCurrentInstance().proxy

    const {
      fetchReminders,
      delaySearchHandler,
      reminderChipColor,
      tableColumns,
      fetchRemindersFilterChoices,

      searchQuery,
      totalReminderListTable,
      loading,
      options,
      userTotalLocal,
      overview,
      reminders,
      choices,
      resourceFilter,
      ownerFilter,
      hideDoneReminders,
    } = useRemindersList(vm.$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES))
    const { getFileExtensionFromUrl } = useFileTable()

    // Delete
    const isDeleteDialogVisible = ref(false)
    const itemToDeleteId = ref(null)

    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDeleteDialogVisible.value = true
    }
    const confirmDelete = async () => {
      if (itemToDeleteId.value !== null) {
        await deleteReminder({
          reminderId: itemToDeleteId.value,
        })
        await fetchReminders()
      }
      isDeleteDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDeleteDialogVisible.value = false
    }

    // Update
    const isUpdateDialogVisible = ref(false)
    const reminderToUpdate = ref(null)
    const updateLoading = ref(false)
    const updateReminderForm = ref(null)

    const updateReminder = (reminder) => {
      reminderToUpdate.value = _.cloneDeep(reminder)
      isUpdateDialogVisible.value = true
    }

    const updateHandler = async (updateData) => {
      loading.value = true
      const { data, success } = await updateReminderAction({
        reminderData: updateData,
      })
      if (success) {
        reminders.value.splice(_.findIndex(reminders.value, { id: data.id }), 1, data)
        await fetchRemindersFilterChoices()
        loading.value = false
      }

      return success
    }

    const confirmUpdate = async () => {
      const isFormValid = updateReminderForm.value.validate()
      if (!isFormValid || reminderToUpdate.value === null) return

      updateLoading.value = true

      const success = await updateHandler(reminderToUpdate.value)
      if (success) {
        reminderToUpdate.value = null
        isUpdateDialogVisible.value = false
      }
      updateLoading.value = false
    }

    const updateDone = async (item) => {
      await updateHandler({
        ...item,
        isDone: !item.isDone,
      })
    }
    const discardUpdate = () => {
      isUpdateDialogVisible.value = false
      reminderToUpdate.value = null
    }

    const getFileReminderToUpdate = computed(() => {
      return reminderToUpdate.value[`${reminderToUpdate.value.assignedResourceType}File`]
    })
    const setFileReminderToUpdate = (value) => {
      reminderToUpdate.value[`${reminderToUpdate.value.assignedResourceType}File`] = value
    }

    // Common
    const filesChoices = ref([])
    const recruiters = ref([])

    watch(isUpdateDialogVisible, async (newVal) => {
      if (newVal) {
        filesChoices.value = await getFilesChoices({
          resourceName: reminderToUpdate.value.assignedResourceType,
          resourceId: reminderToUpdate.value[reminderToUpdate.value.assignedResourceType],
        })
        recruiters.value = await getRecruitersChoices()
      }
    })

    const ownerChoices = computed(() => {
      return recruiters.value
        .filter((user) => !['expert', 'suspended'].includes(user.access))
        .map((rec) => {
          return {
            text: `${rec.user.firstName} ${rec.user.lastName}`,
            value: rec.id,
          }
        })
    })

    const filteredTableColumns = computed(() => {
      return tableColumns(true).filter((column) => column.show)
    })

    return {
      avatarText,
      emptyValueFormatter,
      formatDateToMonthShort,
      fetchReminders,
      deleteItem,
      confirmDelete,
      discardDelete,
      delaySearchHandler,
      reminderChipColor,
      getFileExtensionFromUrl,

      isDeleteDialogVisible,
      filteredTableColumns,
      searchQuery,
      totalReminderListTable,
      loading,
      options,
      userTotalLocal,
      overview,
      reminders,
      choices,
      resourceFilter,
      ownerFilter,
      hideDoneReminders,

      // Update
      updateReminder,
      confirmUpdate,
      updateDone,
      discardUpdate,
      setFileReminderToUpdate,
      getFileReminderToUpdate,
      reminderToUpdate,
      isUpdateDialogVisible,
      updateLoading,
      updateReminderForm,

      // Common
      filesChoices,
      ownerChoices,

      validators: {
        required,
      },

      // icons
      icons: {
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiRefresh,
        mdiCheck,
        mdiPencilOutline,
        mdiFileAccountOutline,
        mdiChevronDown,
      },

      ACTIONS,
      PRIVATE,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
.PDF-Viewer__object {
  height: 70vh;
}

.Image-Viewer__object {
  object-fit: contain;
}
</style>
