import { ref, watch } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'

export default function useRemindersList(handleAsTask) {
  const reminders = ref([])
  const choices = ref({})

  const resourceFilter = ref([])
  const ownerFilter = ref([])
  const hideDoneReminders = ref(true)

  const tableColumns = () => {
    return [
      { text: 'title', value: 'title', class: 'text-uppercase', show: true, width: '250px' },
      {
        text: 'resource',
        value: 'assignedResourceType',
        class: 'text-uppercase',
        sortable: false,
        show: true,
      },
      {
        text: 'assignedTo',
        value: 'owner',
        sortable: true,
        class: 'text-uppercase',
        show: handleAsTask,
      },
      {
        text: 'file',
        value: 'isAssignedToFile',
        sortable: false,
        align: 'center',
        class: 'text-uppercase',
        show: true,
      },
      {
        text: 'remindDate',
        value: 'remindDate',
        sortable: true,
        class: 'text-uppercase',
        show: true,
      },
      {
        text: 'doneDate',
        value: 'doneDate',
        sortable: true,
        class: 'text-uppercase',
        show: handleAsTask,
      },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
        sortable: false,
        class: 'text-uppercase',
        show: true,
      },
    ]
  }

  const searchQuery = ref('')
  const totalReminderListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortDesc: [true],
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
  })
  const userTotalLocal = ref([])
  const overview = ref([])

  const { getAllReminders, getAllRemindersFilterChoices } = useActions('staff', [
    'getAllReminders',
    'getAllRemindersFilterChoices',
  ])

  const fetchRemindersFilterChoices = async () => {
    choices.value = await getAllRemindersFilterChoices({
      resources: resourceFilter.value,
      owner: ownerFilter.value,
      hideDoneReminders: hideDoneReminders.value,
      search: searchQuery.value,
    })
  }

  // fetch data
  const fetchReminders = async () => {
    loading.value = true
    const { data, success } = await getAllReminders({
      resources: resourceFilter.value,
      owner: ownerFilter.value,
      hideDoneReminders: hideDoneReminders.value,
      search: searchQuery.value,
      page: options.value.page,
      pageSize: options.value.itemsPerPage,
      ordering: options.value.sortBy.map((el, index) => {
        const parsedEl = el
          .replaceAll('.', '__')
          .replace('remindDate', 'remind_date')
          .replace('ownerName', 'owner_name')
          .replace('doneDate', 'done_date')

        return `${options.value.sortDesc[index] ? '-' : ''}${parsedEl}`
      }),
    })
    if (success) {
      if (Object.hasOwnProperty.bind(data)('results') && Object.hasOwnProperty.bind(data)('count')) {
        reminders.value = data.results
        totalReminderListTable.value = data.count
      } else {
        reminders.value = data
        totalReminderListTable.value = data.length
      }
      loading.value = false
    }

    await fetchRemindersFilterChoices()
  }

  const timer = ref(null)
  const delaySearchHandler = async () => {
    clearTimeout(timer.value)
    timer.value = setTimeout(async () => {
      await fetchReminders()
    }, 1000)
  }

  watch([resourceFilter, ownerFilter, hideDoneReminders, options], async () => {
    await fetchReminders()
  })

  const reminderChipColor = (item) => {
    if (item.isDone) {
      return 'secondary'
    }

    const remindDate = new Date(item.remindDate)
    const currentDate = new Date()
    const diffTime = remindDate.getTime() - currentDate.getTime()
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    if (diffDays === 0) {
      return 'warning'
    }
    if (diffDays > 0 && diffDays <= 7) {
      return 'primary'
    }
    if (diffDays < 0) {
      return 'error'
    }

    return 'info'
  }

  return {
    fetchReminders,
    delaySearchHandler,
    reminderChipColor,
    tableColumns,
    fetchRemindersFilterChoices,

    searchQuery,
    totalReminderListTable,
    loading,
    options,
    userTotalLocal,
    overview,
    reminders,
    choices,
    resourceFilter,
    hideDoneReminders,
    ownerFilter,
  }
}
